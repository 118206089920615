import DetailViewTextField from 'presentation/components/common/FormikFields/DetailViewTextField';
import FormikRadioField from 'presentation/components/common/FormikFields/FormikRadioField';
import InputContainer from 'presentation/components/common/FormikFields/InputContainer';
import AutoComplete from 'presentation/components/common/FormikFields/LeadAutocomplete';
import { constructConfig } from 'presentation/components/common/FormikFields/SectionRenderer/interface';
import DatePickerWithThaiYear from 'presentation/components/controls/DatePickerWithThaiYear';
import { getDOBError } from 'presentation/pages/car-insurance/LeadDetailsPage/CustomerSection/helper';
import { getString } from 'presentation/theme/localization';
import { isValidNameInput } from 'utils/customerInfo';

import { RadioGroup } from '@alphafounders/ui';
import RadioGroupRow from 'presentation/pages/car-insurance/LeadDetailsPage/CustomerSection/PolicyHolderInformation/RadioGroupRow';
import { errorMessage } from 'presentation/components/InsurerInfoSection/InsurerInfoSection.helper';
import validateEmailWithoutSpecialChars from 'shared/validators/email';

export enum HEALTH_CUSTOMER_ROWS {
  firstName = 'customer/firstName',
  lastName = 'customer/lastName',
  gender = 'customer/gender',
  dob = 'customer/dob',
  language = 'customer/isThaiNational',
  age = 'customer/age',
}

export enum HEALTH_POLICYHOLDER_ROWS {
  policyHolderType = 'policyHolder/type',
  policyHolderTitle = 'policyHolder/title',
  policyHolderFirstName = 'policyHolder/firstName',
  policyHolderLastName = 'policyHolder/lastName',
  policyHolderNationalId = 'policyHolder/nationalId',
  policyHolderDob = 'policyHolder/dob',
  policyHolderAge = 'policyHolder/age',
  policyJobDescription = 'policyHolder/jobDescription',
  policyHolderLocale = 'policyHolder/locale',
  policyHolderOccupation = 'policyHolder/occupation',
  policyHolderPassport = 'policyHolder/passport',
  policyHolderRace = 'policyHolder/race',
}

export enum HEALTH_BENEFICIARY_ROWS {
  beneficiaryTitle = 'beneficiaries/0/title',
  beneficiaryEmail = 'beneficiaries/0/email',
  beneficiaryFirstName = 'beneficiaries/0/firstName',
  beneficiaryLastName = 'beneficiaries/0/lastName',
  beneficiaryGender = 'beneficiaries/0/gender',
  beneficiaryPhone = 'beneficiaries/0/phone',
  beneficiaryRelationship = 'beneficiaries/0/relationship',
}
export enum HEALTH_INSURER_ROWS {
  CURRENT_INSURER = 'currentInsurer',
  PREFERRED_INSURER = 'preferredInsurer',
  PREFERRED_PRODUCT_CATEGORY = 'insurance/category',
  PREFERRED_PRODUCT_COVERAGES = 'insurance/coverages',
  PREFERRED_PRODUCT_TYPE = 'insurance/type',
  PREFERRED_PRODUCT_PLAN = 'insurance/plan',
  POLICY_START_DATE = 'policyStartDate',
  DELIVERY_OPTION = 'checkout/deliveryOption',
  UNDERWRITING_STATUS = 'underwritingStatus',
}

export const getInsurerInfoDataSchema = () => ({
  [HEALTH_INSURER_ROWS.CURRENT_INSURER]: constructConfig(AutoComplete, {
    name: HEALTH_INSURER_ROWS.CURRENT_INSURER,
    title: getString('healthLead.currentInsurer'),
    handleUpdate: () => null,
    options: [],
    value: '',
    dataTestId: 'currentInsurer',
    isReadOnly: false,
    multiple: false,
    showAsterisk: false,
    disableClearable: true,
    isDisabled: false,
    placeholder: getString('text.pleaseSelect'),
  }),
  [HEALTH_INSURER_ROWS.PREFERRED_INSURER]: constructConfig(AutoComplete, {
    name: HEALTH_INSURER_ROWS.PREFERRED_INSURER,
    title: getString('healthLead.preferredInsurer'),
    value: '',
    handleUpdate: () => null,
    dataTestId: 'preferredInsurer',
    isReadOnly: false,
    options: [],
    multiple: false,
    showAsterisk: false,
    disableClearable: true,
    isDisabled: false,
    placeholder: getString('text.pleaseSelect'),
  }),
  [HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_CATEGORY]: constructConfig(
    AutoComplete,
    {
      dataTestId: 'customerLastName',
      name: HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_CATEGORY,
      title: getString('healthLead.preferredProductCategory'),
      showAsterisk: false,
      options: [],
      value: '',
      disableClearable: true,
      isDisabled: false,
      placeholder: getString('text.pleaseSelect'),
      handleUpdate: () => null,
    }
  ),
  [HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_TYPE]: constructConfig(AutoComplete, {
    name: HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_TYPE,
    title: getString('healthLead.preferredProductType'),
    dataTestId: 'preferredType',
    showAsterisk: false,
    options: [],
    value: '',
    disableClearable: true,
    isDisabled: false,
    placeholder: getString('text.pleaseSelect'),
    handleUpdate: () => null,
  }),
  [HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_PLAN]: constructConfig(AutoComplete, {
    name: HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_PLAN,
    title: getString('healthLead.preferredIpdOpdPlan'),
    dataTestId: 'preferredPlan',
    showAsterisk: false,
    value: '',
    options: [],
    disableClearable: true,
    isDisabled: false,
    placeholder: getString('text.pleaseSelect'),
    handleUpdate: () => null,
  }),
  [HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_COVERAGES]: constructConfig(
    AutoComplete,
    {
      name: HEALTH_INSURER_ROWS.PREFERRED_PRODUCT_COVERAGES,
      title: getString('healthLead.preferredCoverageItems'),
      dataTestId: 'preferredcoverages',
      showAsterisk: false,
      options: [],
      multiple: true,
      disableClearable: true,
      isDisabled: false,
      handleUpdate: () => null,
    }
  ),
  [HEALTH_INSURER_ROWS.POLICY_START_DATE]: constructConfig(
    DatePickerWithThaiYear,
    {
      name: HEALTH_INSURER_ROWS.POLICY_START_DATE,
      title: getString('healthLead.preferredPolicyStartDate'),
      onChangeDate: () => null,
      showAsterisk: true,
      value: '',
      validateFn: (val) => errorMessage(val),
      textFieldError: false,
      isDisabled: true,
    },
    InputContainer
  ),
  [HEALTH_INSURER_ROWS.DELIVERY_OPTION]: constructConfig(DetailViewTextField, {
    name: HEALTH_INSURER_ROWS.DELIVERY_OPTION,
    title: getString('healthLead.deliveryOption'),
    isReadOnly: true,
    dataTestId: 'deliveryOption-readOnly',
  }),
  [HEALTH_INSURER_ROWS.UNDERWRITING_STATUS]: constructConfig(AutoComplete, {
    name: HEALTH_INSURER_ROWS.UNDERWRITING_STATUS,
    title: getString('healthLead.underwritingStatus'),
    dataTestId: 'underwritingStatus',
    showAsterisk: true,
    options: [],
    isDisabled: false,
    disableClearable: true,
    handleUpdate: () => null,
  }),
});

export const HealthCustomerSectionConfig = {
  [HEALTH_CUSTOMER_ROWS.firstName]: constructConfig(DetailViewTextField, {
    dataTestId: 'customerFirstName',
    name: HEALTH_CUSTOMER_ROWS.firstName,
    title: getString('leadDetailFields.firstName'),
    showAsterisk: true,
    validationFn: isValidNameInput,
    textFieldError: false,
  }),
  [HEALTH_CUSTOMER_ROWS.lastName]: constructConfig(DetailViewTextField, {
    dataTestId: 'customerLastName',
    name: HEALTH_CUSTOMER_ROWS.lastName,
    title: getString('leadDetailFields.lastName'),
    showAsterisk: true,
    validationFn: isValidNameInput,
    textFieldError: false,
  }),
  [HEALTH_CUSTOMER_ROWS.gender]: constructConfig(AutoComplete, {
    dataTestId: 'customerGender',
    options: [],
    name: HEALTH_CUSTOMER_ROWS.gender,
    title: getString('leadDetailFields.gender'),
    disableClearable: true,
    showAsterisk: true,
    handleUpdate: () => null,
  }),
  [HEALTH_CUSTOMER_ROWS.dob]: constructConfig(
    DatePickerWithThaiYear,
    {
      name: HEALTH_CUSTOMER_ROWS.dob,
      title: getString('leadDetailFields.dob'),
      onChangeDate: () => null,
      value: '',
      validateFn: getDOBError,
      textFieldError: false,
    },
    InputContainer
  ),
  [HEALTH_CUSTOMER_ROWS.age]: constructConfig(DetailViewTextField, {
    name: HEALTH_CUSTOMER_ROWS.age,
    title: getString('leadDetailFields.age'),
    isReadOnly: true,
  }),
  [HEALTH_CUSTOMER_ROWS.language]: constructConfig(FormikRadioField, {
    name: HEALTH_CUSTOMER_ROWS.language,
    title: getString('leadDetailFields.language'),
    value: '',
    row: true,
  }),
};

export const HealthBeneficiarySectionConfig = {
  [HEALTH_BENEFICIARY_ROWS.beneficiaryTitle]: constructConfig(AutoComplete, {
    dataTestId: 'customerTitle',
    name: HEALTH_BENEFICIARY_ROWS.beneficiaryTitle,
    title: getString('leadDetailFields.title'),
    showAsterisk: false,
    disableClearable: true,
    options: [],
    handleUpdate: () => null,
  }),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryFirstName]: constructConfig(
    DetailViewTextField,
    {
      dataTestId: 'customerFirstName',
      name: HEALTH_BENEFICIARY_ROWS.beneficiaryFirstName,
      title: getString('leadDetailFields.firstName'),
      showAsterisk: false,
      validationFn: isValidNameInput,
      textFieldError: false,
    }
  ),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryLastName]: constructConfig(
    DetailViewTextField,
    {
      dataTestId: 'customerLastName',
      name: HEALTH_BENEFICIARY_ROWS.beneficiaryLastName,
      title: getString('leadDetailFields.lastName'),
      showAsterisk: false,
      validationFn: isValidNameInput,
      textFieldError: false,
    }
  ),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryGender]: constructConfig(AutoComplete, {
    dataTestId: 'customerGender',
    options: [],
    name: HEALTH_BENEFICIARY_ROWS.beneficiaryGender,
    title: getString('leadDetailFields.gender'),
    showAsterisk: false,
    handleUpdate: () => null,
  }),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryEmail]: constructConfig(
    DetailViewTextField,
    {
      dataTestId: 'customerEmail',
      name: HEALTH_BENEFICIARY_ROWS.beneficiaryEmail,
      title: getString('leadDetailFields.email'),
      showAsterisk: false,
      validationFn: (email) => {
        const isValid = validateEmailWithoutSpecialChars().isValidSync(email);
        if (!isValid) {
          return getString('errors.invalidData');
        }
        return '';
      },
      textFieldError: false,
    }
  ),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryPhone]: constructConfig(
    DetailViewTextField,
    {
      dataTestId: 'customerPhone',
      name: HEALTH_BENEFICIARY_ROWS.beneficiaryPhone,
      title: getString('leadDetailFields.phone'),
      showAsterisk: false,
      validationFn: (phone) => {
        const regex = /^(\+[1-9]\d{1,3}\d{8,11}|0\d{9})$/;
        if (!regex.test(phone)) {
          return getString('errors.invalidValue');
        }
        return '';
      },
      textFieldError: false,
    }
  ),
  [HEALTH_BENEFICIARY_ROWS.beneficiaryRelationship]: constructConfig(
    DetailViewTextField,
    {
      dataTestId: 'customerRelationship',
      name: HEALTH_BENEFICIARY_ROWS.beneficiaryRelationship,
      title: getString('text.relationship'),
      showAsterisk: false,
      validationFn: isValidNameInput,
      textFieldError: false,
    }
  ),
};

export const getPolicyHolderSectionConfig = () => ({
  [HEALTH_POLICYHOLDER_ROWS.policyHolderType]: constructConfig(
    RadioGroup,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderType,
      title: 'policyHolderType',
      options: [],
      value: '',
      orientation: 'vertical',
      field: 'policyHolderType',
      className: 'py-3 px-2',
      radioType: 'tick',
    },
    RadioGroupRow
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderTitle]: constructConfig(AutoComplete, {
    name: HEALTH_POLICYHOLDER_ROWS.policyHolderTitle,
    dataTestId: 'policyTitle',
    title: getString('leadDetailFields.title'),
    showAsterisk: true,
    disableClearable: true,
    options: [],
    handleUpdate: () => null,
  }),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderFirstName]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderFirstName,
      dataTestId: 'policyHolderFirstName',
      title: getString('leadDetailFields.firstName'),
      showAsterisk: true,
      validationFn: isValidNameInput,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderLastName]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderLastName,
      dataTestId: 'policyHolderLastName',
      title: getString('leadDetailFields.lastName'),
      showAsterisk: true,
      validationFn: isValidNameInput,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderNationalId]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderNationalId,
      dataTestId: 'policyHolderNationalId',
      title: getString('leadDetailFields.nationalId'),
      showAsterisk: true,
      validationFn: (id) => {
        if (id.length > 13) {
          return getString('errors.exceedCharacters', {
            maxLength: 13,
          });
        }

        if (id.length < 13 || !id.match(/^[A-Za-z0-9]+$/)) {
          return getString('errors.invalidID');
        }
        return '';
      },
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderDob]: constructConfig(
    DatePickerWithThaiYear,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderDob,
      title: getString('leadDetailFields.dob'),
      onChangeDate: () => null,
      value: '',
      validateFn: getDOBError,
      textFieldError: false,
    },
    InputContainer
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderAge]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderAge,
      title: getString('leadDetailFields.age'),
      isReadOnly: true,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderPassport]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderPassport,
      title: getString('leadDetailFields.passport'),
      showAsterisk: false,
      isReadOnly: false,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderRace]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderRace,
      title: getString('leadDetailFields.race'),
      showAsterisk: true,
      isReadOnly: false,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderOccupation]: constructConfig(
    DetailViewTextField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderOccupation,
      title: getString('leadDetailFields.occupation'),
      isReadOnly: false,
      showAsterisk: true,
      textFieldError: false,
    }
  ),
  [HEALTH_POLICYHOLDER_ROWS.policyHolderLocale]: constructConfig(
    FormikRadioField,
    {
      name: HEALTH_POLICYHOLDER_ROWS.policyHolderLocale,
      title: getString('leadDetailFields.language'),
      isReadOnly: false,
      row: true,
      value: '',
    }
  ),
});
