import { getString } from 'presentation/theme/localization';
import { csvTypeFiles } from 'shared/constants';
import { ImportType } from 'shared/constants/importFile';
import {
  set,
  differenceInDays,
  isSameDay,
  isBefore,
  isValidDateFormat,
} from 'utils/datetime';

interface IProps<T> {
  file: T;
  csvName: string;
  isErrorCheck?: boolean;
  requiredColumns: string[] | string[][];
  template: string[];
  [key: string]: any;
}

interface FileResult {
  fileName: string;
  name: string;
  fileType: string;
  fileSize: number;
  result: any[];
}

const isCsvFileError = (fileResult: FileResult): string | undefined => {
  let message: string | undefined;
  if (!csvTypeFiles.includes(fileResult.fileType)) {
    return getString('text.requiredCsvValidation');
  }
  return message;
};

const checkRowsRequiredValidation = (
  requiredArray: string[] | string[][],
  result: any[],
  csvName: string
): any => {
  const checkRowsRequireErrorMessages: string[] = [];

  if (csvName === 'renewal package') {
    result.forEach((rows: any, index: number) => {
      const required: any =
        rows.insurer_accept === '1' ? requiredArray[0] : requiredArray[1];
      for (const [key, value] of Object.entries(rows)) {
        if (required.includes(key)) {
          if (!value) {
            checkRowsRequireErrorMessages.push(
              getString('text.requiredRowsValidation', {
                column: key,
                name: csvName,
                row: index + 1,
              })
            );
          }
        }
      }
    });
  }
  if (csvName !== 'Customer Profile' && csvName !== 'renewal package') {
    const required: string[] = requiredArray as string[];
    result.forEach((rows: any, index: number) => {
      for (const [key, value] of Object.entries(rows)) {
        if (required.includes(key)) {
          if (!value) {
            checkRowsRequireErrorMessages.push(
              getString('text.requiredRowsValidation', {
                column: key,
                name: csvName,
                row: index + 1,
              })
            );
          }
        }
      }
    });
  }

  return checkRowsRequireErrorMessages;
};

const checkTemplatesValidation = (
  csvTemplate: string[],
  result: any[],
  type: ImportType
) => {
  const checkTemplatesErrorMessages: string[] = [];
  const [firstCol, secondCol] = result;

  if (type === ImportType.Discounts) {
    if (secondCol) {
      csvTemplate.forEach((cols, index) => {
        if (secondCol[index] !== cols) {
          checkTemplatesErrorMessages.push(
            getString('text.requiredColumnValidation', { column: cols })
          );
        }
      });
    }
    return checkTemplatesErrorMessages;
  }

  if (firstCol) {
    csvTemplate.forEach((cols: any) => {
      if (firstCol[cols] === undefined) {
        checkTemplatesErrorMessages.push(
          getString('text.requiredColumnValidation', { column: cols })
        );
      }
    });
  }
  return checkTemplatesErrorMessages;
};

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validGender = ['m', 'f'];
const dateFormat = 'yyyy-MM-dd';
const validZeroOneBool = [0, 1];
const validInsuranceType = ['1', '2', '2+', '3', '3+'];

const validateString = (value: string) =>
  value ? typeof value === 'string' && Number.isNaN(+value) : true;

const validateNumber = (value: string) =>
  value ? typeof +value === 'number' && !Number.isNaN(+value) : true;

const validateEmail = (value: string) =>
  value ? emailRegex.test(value) : true;

const validateGender = (value: string) =>
  value ? validGender.includes(value) : true;

const validateDate = (value: string) =>
  value ? isValidDateFormat(value, dateFormat) : true;

const validateZeroOneBool = (value: number) =>
  value ? validZeroOneBool.includes(+value) : true;

const validateInsuranceType = (value: string) =>
  value ? validInsuranceType.includes(value) : true;

export const validateEffectiveTime = (value: string | Date) => {
  const currentDate = new Date(value);
  const daysDifference = differenceInDays(currentDate, new Date());

  if (daysDifference >= 0) {
    if (isSameDay(currentDate, new Date())) {
      return isBefore(currentDate, set(new Date(), { hours: 19, minutes: 0 }));
    }
    return true;
  }
  return false;
};

export const validateDataWithType = (
  dataType: string,
  value: string | any
): boolean => {
  switch (dataType) {
    case 'string':
      return validateString(value);
    case 'number':
      return validateNumber(value);
    case 'string|number':
      return validateString(value) || validateNumber(value);
    case 'email':
      return validateEmail(value);
    case 'gender':
      return validateGender(value);
    case 'date':
      return validateDate(value);
    case 'effectiveDate':
      if (value instanceof Date) {
        return validateEffectiveTime(value);
      }
      return validateDate(value) && validateEffectiveTime(value);
    case 'zeroOneBool':
      return validateZeroOneBool(value);
    case 'insuranceType':
      return validateInsuranceType(value);
    default:
      return false;
  }
};

const rowsDataValidationWithType = (
  templateWithType: any[],
  result: any[]
): string[] => {
  const checkRowDataValidationWithType: string[] = [];
  result.forEach((rows: any, index: number) => {
    Object.entries(rows).forEach(([key, value]) => {
      templateWithType.forEach((field) => {
        if (field.name === key) {
          const validData = validateDataWithType(field.dataType, value);
          if (!validData) {
            checkRowDataValidationWithType.push(
              getString('text.invalidDataFormat', {
                column: key,
                row: index + 1,
              })
            );
          }
        }
      });
    });
  });

  return checkRowDataValidationWithType;
};

const csvValidationErrors = (props: IProps<any>): string[] => {
  let errors: string[] = [];
  if (props.file.errorFileMessage === 'Delimiter') {
    errors = [...errors, getString('text.missingHeaderFile')];
    props.template.forEach((cols: any) => {
      errors.push(getString('text.requiredColumnValidation', { column: cols }));
    });
  }

  if (props.file.fileSize > 0) {
    const csvFileError = isCsvFileError(props.file);
    if (csvFileError) {
      errors.push(csvFileError);
    } else {
      if (props.file.errorFileMessage === 'FieldMismatch') {
        errors = [...errors, getString('text.fieldMismatch')];
      }

      if (!props.file?.result?.length) {
        errors.push(getString('text.emptyFile'));
        return errors;
      }

      if (
        props.maximumUpload &&
        props.file?.result?.length > props.maximumUpload
      ) {
        errors = [
          ...errors,
          getString('text.maximumUpload', {
            type: props.csvName.toLowerCase(),
            maxNumber: props.maximumUpload,
          }),
        ];
      }

      const headerRequiredErrors = checkTemplatesValidation(
        props.template,
        props.file.result,
        props.importModalType
      );

      const fieldRequiredValidation = checkRowsRequiredValidation(
        props.requiredColumns,
        props.file.result,
        props.csvName
      );

      const fieldWithDataValidation = props.templateWithType
        ? rowsDataValidationWithType(props.templateWithType, props.file.result)
        : [];

      if (headerRequiredErrors.length) {
        errors = [...errors, ...headerRequiredErrors];
      }
      if (fieldRequiredValidation.length) {
        errors = [...errors, ...fieldRequiredValidation];
      }
      if (fieldWithDataValidation.length) {
        errors = [...errors, ...fieldWithDataValidation];
      }
    }
  }

  return errors;
};
export default csvValidationErrors;
