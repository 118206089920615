import 'regenerator-runtime/runtime';
import * as amplitude from '@amplitude/analytics-browser';
import { NewRelicProvider } from '@careos/newrelic';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { initNewRelic } from './config/newrelic';
import App from './App';

import { store } from 'presentation/redux/store';
import { configureLocalization } from 'presentation/theme/localization';
import { flagSmithEnv } from 'utils/env';

import 'react-quill/dist/quill.bubble.css';
import '@alphafounders/ui/index.css';
import 'scss/index.scss';
import './index.css';

import './gtm';

const amplitudeApiKey = process.env.VITE_AMPLITUDE_API_KEY;

if (amplitudeApiKey) {
  amplitude.init(amplitudeApiKey, {
    defaultTracking: false,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

configureLocalization();

const _newrelic = initNewRelic();

root.render(
  <NewRelicProvider newrelic={_newrelic}>
    <FlagsmithProvider
      options={{
        environmentID: flagSmithEnv,
        cacheFlags: true,
      }}
      flagsmith={flagsmith}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </FlagsmithProvider>
  </NewRelicProvider>
);
